.element_type {
  background-color: #3b3b3b;
  color: #fff;
  left: 0px;
  top: -19px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}

.heading_input {
  border: 0px;
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  padding: 8px 15px;
  background-color: #fff;
  &:focus {
    outline: none;
  }
}
.description_input {
  border: 0px;
  width: 100%;
  background-color: transparent;
  resize: vertical;
  font-size: 16px;
  border: 1px solid #d9d9d9;
  padding: 8px 15px;
  background-color: #fff;
  &:focus {
    outline: none;
  }
}

.vertical_space {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 12px 15px;
}

.form_builder_layout {
  // display: flex
  max-width: 1100px;
}
.form_builder {
  flex: 1;
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  padding: 15px 45px;
}
.form_tools {
  width: 100%;
  display: flex;
  margin-top: 25px;
  // margin-left: 45px;
  //   background-color: #000;
}
.drag_item {
  position: relative;
}
.button_tool {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 5px;
  margin-bottom: 5px;
  background-color: #002371;
  border: 1px solid #3a5cb2;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background-color: #021b53;
  }
  .text {
    margin-top: 5px;
    font-size: 17px;
    display: block;
    color: #a6bbf2;
  }
  .icon {
    display: block;
    font-size: 28px;
    color: #fff;
    margin-right: 12px;
  }
}

// elements
.drag_item {
  margin-top: 40px;
}
.table_form_builder {
  th,
  td {
    border: 1px solid #d9d9d9;
    padding: 8px 15px;
  }
}
.group_item {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 20px 35px;
}

.form_builder {
  .nestable-item--with-children {
    .nestable-list {
      padding: 1px 32px 28px !important;
      background-color: #d8ebfd;
    }
  }
}

.table_form {
  display: flex;
}

.table_form_display {
  margin-left: 0px;
}

.tab_inputs {
  display: block;
  border: 1px solid #d9d9d9;
  padding: 5px 12px;
  width: 300px;
}

.element {
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.delete_tab {
  position: absolute;
  right: 11px;
  background-color: #ffffff;
  font-size: 9px;
  color: #e60a0a;
  border: 1px solid #ffb2b2;
  top: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px;
}
.condition_tab {
  position: absolute;
  right: 46px;
  background-color: #ffffff;
  font-size: 9px;
  border: 1px solid #002371;
  top: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px;
}

.add_tab {
  position: absolute;
  right: 83px;
  top: 13px;
  height: 30px;
  width: 30px;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid #002371;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.child_tab_elements {
  background-color: #5e82a5;
  padding: 15px;
  margin: 0 -15px;
}

.floating_tab_buttons {
  position: absolute;
  right: 53px;
  top: 13px;
  button {
    height: 33px;
    width: 33px;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid #002371;
    background-color: #fff;
    cursor: pointer;
    margin: 0 5px;
  }
}
.empty_elements {
  color: rgb(236 242 249);
  margin: 0;
  text-align: center;
  padding: 15px;
}
