footer {
  background-color: #111214;
  @media (max-width: 650px) {
    text-align: center;
  }
  span {
    color: #f5d686;
  }
  a {
    color: #fff;
    &:hover,
    &.selected,
    &.active {
      color: #e6a903;
    }
  }
  ul {
    padding: 0px;
    margin: 0px;
    margin-right: -10px;
    @media (max-width: 650px) {
      margin-bottom: 20px;
    }
  }
  li {
    display: inline-block;
    margin: 0 10px;
    @media (max-width: 440px) {
      display: block;
      text-align: center;
      margin: 15px 0px;
    }
  }
}

.footer_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  @media (max-width: 650px) {
    flex-direction: column-reverse;
  }
}
