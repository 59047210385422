.widget {
  padding: 25px 15px;
  background-color: #eee;
  position: relative;
  margin: 5px;
}
.widget_container {
  display: flex;
  flex-wrap: wrap;
}
.widget_sizing {
  position: absolute;
  right: 21px;
  top: 15px;
  .anticon {
    cursor: pointer;
    margin: 0 8px;
  }
}

.signature_input {
  font-family: 'Square Peg', cursive;
  font-size: 60px;
  width: 100%;
  outline: none;
  border: 0px;
  background-color: #eee;
  padding: 4px 21px;
}

.sigCanvas {
  background-color: #eee;
  padding: 4px 21px;
}

.signature_modal {
  .ant-modal-body {
    padding: 10px;
  }
}

.scoped_wrapper {
  display: flex;
  margin: 0 -7.5px;
}
.scoped_list {
  border: 1px solid #eee;
  flex: 1;
  width: calc(100% - 15px);
  margin: 0 7.5px;
  padding: 15px;
  background-color: #fff;
}

.in_out_item {
  border: 1px solid #eee;
  padding: 12px 5px;
  &:nth-child(odd) {
    background-color: rgba(238, 238, 238, 0.31);
  }
}

.in_out_scope_droppable_area {
  height: 100%;
}
