.home_nav {
  position: relative;
  z-index: 3;
  background-image: linear-gradient(to bottom, #2d3136, #2d3138 62%, #393e46, #393e46);
}

.nav_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 20px 0px;
  @media (max-width: 992px) {
    font-size: 13px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  li {
    display: inline-block;
    @media (max-width: 768px) {
      border-bottom: 1px solid rgb(19, 4, 161);
      font-size: 18px;
      display: block;
    }
    a {
      padding: 2px 10px;
      @media (max-width: 768px) {
        padding: 10px 25px;
        display: block;
      }
    }
  }

  a {
    color: #fff;
    &:hover,
    &.selected,
    &.active {
      color: #e6a903;
    }
  }

  svg {
    @media (max-width: 992px) {
      width: 130px;
    }
  }
}

.auth_btn {
  margin-right: -5px;
  @media (max-width: 768px) {
    margin-top: 25px;
    padding-left: 20px;
  }
  a {
    margin: 0px 5px;
    border-radius: 2px;
    width: 115px;
    display: inline-block;
    text-align: center;
    padding: 4px 25px;
    @media (max-width: 992px) {
      padding: 3px 5px;
      width: 80px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
      padding: 5px 5px;
      width: 105px;
    }
  }
}

.btn {
  padding: 8px 30px;
  display: inline-block;
  border-radius: 2px;
  color: #fff;
  transition: all ease 0.2s;
  &.default {
    // background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.637);
    &:hover {
      border-color: #fff;
      color: #000;
      background-color: #fff;
    }
  }
  &.primary {
    background-color: #fbbe1e;
    border: 1px solid #fbbe1e;
    color: #141414;
    &:hover {
      border: 1px solid #f3b006;
      background-color: #f3b006;
      color: #141414;
    }
  }
}

.menu {
  display: none;
  color: #fff;
  span {
    font-size: 35px;
  }
  @media (max-width: 768px) {
    display: inline-block;
  }
}

.nav_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 300px);
  max-width: 900px;
  @media (max-width: 992px) {
    width: calc(100% - 160px);
  }
  @media (max-width: 768px) {
    max-width: 280px;
    display: block;
    width: calc(100% - 90px);
    position: fixed;
    background-color: #023bb8;
    top: 0px;
    left: -100%;
    bottom: 0px;
    height: 100vh;
    z-index: 99999;
    transition: all ease 0.4s;
  }
}

.close_menu {
  display: none;
  label {
    color: #fff;
    position: absolute;
    top: 25px;
    right: 15px;
    span {
      font-size: 35px;
    }
  }
  @media (max-width: 768px) {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.671);
    width: 100%;
    min-height: 100vh;
    top: 0px;
    left: 0px;
    bottom: 0px;
  }
}

#menu:checked {
  @media (max-width: 768px) {
    & + label + div {
      left: 0px;
    }
    & + label + div + label {
      display: block;
    }
  }
}
