.dashboard {
  padding-top: 30px;

  .col-md-12 {
    margin-bottom: 30px;
  }

  .ant-card {
    height: 100%;
  }

  .ant-card {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    justify-content: space-between;

    &::before,
    &::after {
      display: none;
    }
  }
}

.card_footer {
  display: flex;
  color: #6f6f6f;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  font-size: 13px;

  a {
    font-size: 12px;
    background-color: #001529;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px 4px;
    margin-left: 15px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 17px;
      vertical-align: middle;
      padding-left: 7px;
    }
  }
}

.aspirations_list {
  h6 {
    font-size: 15px;
    margin-bottom: 0px;
  }
}

.aspiration_item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 15px 0px;

  &:last-child {
    border-bottom: 0px;
  }
}

.expand_flex {
  flex: 1;
  margin-left: 15px;
}

.spaced_div {
  display: flex;
  justify-content: space-between;

  span {
    color: #6f6f6f;
    font-size: 13px;
  }

  div:nth-child(even) {
    text-align: right;
  }
}

.flex_title {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span.title {
    font-size: 25px;
  }
}

.dashboard > .row > .col-xl-4 .ant-card-body {
  max-height: 600px;
  overflow-y: scroll;
}

.dashboard {
  .ant-select-single {
    min-width: 105px;
    margin-bottom: 20px;
  }

  .ant-checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    // grid-auto-rows: 20px;
    grid-gap: 1.5px;
  }

  .ant-checkbox-group-item {
    // margin: 3px 0px;
  }
}

div[data-chart-source-type='G2Plot'] + div > div {
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 35px;
  justify-content: center;

  & > span {
    padding: 15px 50px !important;
    border: 1px solid #dbdbdb;
    margin-left: -1px;
  }

  .ant-select-single {
    padding-left: 10px;
    // margin: 0px;
  }
}
