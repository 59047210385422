.auth {
  background-color: #faf7f7;
  padding: 80px 0px;
  min-height: calc(100vh - 178px);
  @media (max-width: 768px) {
    padding: 50px 0px;
  }
  h1 {
    color: #002371;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 5px;
  }
  h2 {
    color: #3c3c3c;
    margin-bottom: 25px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2d3136;
    border-color: #2d3136;
  }
  .ant-radio-button-wrapper:hover {
    color: #23262b;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background: #2d3136;
    &:hover {
      background: #2d3136;
    }
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    border-right-color: #2d3136;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(67, 73, 82, 0.11);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #2d3136;
    border-color: #2d3136;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #23262b;
    border-color: #23262b;
  }
}

.signup_features {
  padding-right: 65px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-right: 0px;
    max-width: 100%;
    margin-bottom: 35px;
  }
  ul {
    list-style: none;
    padding: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
    li {
      position: relative;
      padding: 5px 0px 5px 30px;
      font-size: 16px;
      color: #6f6f6f;
      span {
        position: absolute;
        left: 0px;
        top: 6px;
        color: #069b3a;
      }
    }
  }
}

.signup {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 0px;
    display: block;
  }
}

.base_form {
  position: relative;
  border-radius: 5px;
  box-shadow: 0 9px 48px 0 rgba(24, 144, 255, 0.1);
  background-color: #fff;
  padding: 25px 35px;
  max-width: 450px;
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  select {
    width: 100%;
    border: 1px solid #b5b5b5;
    background-color: #faf7f7;
    border-radius: 2px;
    padding: 5px 10px;
  }
  a {
    color: #e4a403;
  }
  span {
  }
}
.login {
  margin: 0 auto;
  margin-top: 100px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  h1 {
    margin-bottom: 20px;
    text-align: center;
  }
}
.form_group {
  margin-bottom: 15px;
  &.half {
    display: flex;
    justify-content: space-between;
    div {
      width: calc(50% - 7.5px);
    }
  }
}

.form_label {
  display: block;
  color: #3c3c3c;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.primary_form {
  background-color: #fbbe1e;
  color: #141414;
  width: 100%;
  border: 0px;
  border-radius: 2px;
  margin-top: 25px;
  padding: 10px;
  font-weight: 500;
  transition: all ease 0.2s;
  &:hover {
    cursor: pointer;
    background-color: #f3b006;
    color: #141414;
  }
}

.input_mask {
  position: relative;
  button {
    position: absolute;
    right: 0px;
    height: 100%;
    border: 0px;
    background-color: transparent;
    padding-top: 7px;
    padding-right: 10px;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    span {
      font-size: 20px;
    }
  }
}

.help_text {
  padding-top: 5px;
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  a {
    color: #3c3c3c;
    &:hover {
      color: #e4a403;
    }
  }
}

.success_signup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  align-items: center;
  text-align: center;
  .material-icons {
    font-size: 65px;
    margin-top: 45px;
    color: #069b3a;
  }
  h3 {
    font-size: 25px;
    color: #3c3c3c;
  }
  p {
    color: #6f6f6f;
    font-size: 17px;
  }
}
.input-error-message {
  color: red;
}
