.portfolio-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 35px;

  input {
    border: 0px;
    width: 29px;
    text-align: right;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.portfolio-column {
  flex: 1 1;
  border: 1px solid #444;
  margin: 0px;
  transition: flex 0.3s ease;
  cursor: pointer;
  background: #fff;
  .value_label {
    display: none;
  }
  &.data {
    .portfolio-section-header {
      opacity: 0;
    }
  }
}

.portfolio-column.first {
  flex: 0 0 250px;
  cursor: default;
}

.portfolio-column.expanded {
  flex: 1.5;
  .value_label {
    display: inline-block;
  }
}

.portfolio-header {
  background-color: #000000;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.portfolio-section {
  margin: 10px 0;
  padding: 10px;
}

.portfolio-section-header {
  font-weight: bold;
}

.toastui-chart-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
