.stratergy_container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 100%;
  min-height: 680px;
  margin-top: 50px;
}

.stratergy_box_container {
  //background: linear-gradient(to right, red, purple);
  cursor: pointer;
  box-shadow: 0px 3px 8px 3px rgba(241, 241, 241, 0.33);
  margin: 5px;
  padding: 1px;
  border-radius: 10px;
}

.stratergy_box {
  // width: calc(33.333% - 20px);
  width: 28%;
  padding: 10px;
  border: 1px solid rgb(202, 202, 202);
  min-height: 200px;
  background-color: #eee;
  position: relative;
  border-radius: 2%;
}

.stratergy_card {
  margin: 5px;
  padding: 10px;
  // border: 1px solid blue;
  position: relative;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;

  p {
    display: none;
  }

  h4 {
    margin: 0px;
  }

  .stratergy_card_summary {
    margin: 0px;
    padding: 0px;
  }

  .stratergy_card_actions {
    display: none;
  }
}

.column-stratergy-color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.icon_center_header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
}

.stratergy_box:nth-child(even) {
  // overflow: hidden;
  position: relative;
  border: 0px;
  width: calc(44% - 20px);

  .stratergy_box_container {
    margin: 0px;
    border: 0px;
    background-color: #fff;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 2%;

    p {
      display: block;
      margin-bottom: 1em;
    }

    h4 {
      margin-bottom: 0.5em;
    }

    .stratergy_card_summary {
      margin-top: 0px;
      padding: 10px;
    }

    .stratergy_card_actions {
      display: flex;
    }
  }
}

.stratergy_card_number {
  position: absolute;
  left: -26px;
  top: -2px;
  font-weight: 600;
  background-color: rgb(205, 205, 205);
  width: 25px;
  text-align: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.stratergy_card_summary {
  margin-top: 20px;
  padding: 10px;
}

.stratergy_card_actions {
  // border: 1px solid #000;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
    border-radius: 40px;
    padding: 8px 20px;
    margin: 0 10px;
    border: 0px;
    width: 190px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.later {
      background-color: #e5e5e5;

      &:hover {
        background-color: #dadada;
      }
    }

    &.choose {
      color: #fff;
      background-color: #00ad3f;
      transition: all ease 0.3s ease;

      &:hover {
        background-color: #019a39;
      }
    }
  }
}

.box_title {
  position: absolute;
  top: -32px;
  font-size: 15px;
  font-weight: 600;
  left: 0px;
}

//.stratergy_box:last-child {
//    background-color: #f8f8f8;
//
//    .stratergy_card {
//        // background-color: #00ad3f;
//        background: linear-gradient(to right, red, purple);
//
//        h4 {
//            color: #fff;
//        }
//    }
//}

.drop_instructions {
  width: 200px;
  display: block;
  width: calc(100% - 20px);
  display: block;
  border: 1px dashed #919191;
  text-align: center;
  padding: 15px;
  position: absolute;
  left: 10px;
  top: calc(50% - 28px);
  opacity: 0.3;
  z-index: 1;
}
