.element_row {
  display: flex;
  flex-wrap: wrap;
  label {
    display: block;
  }
}
.element_container {
  padding: 15px;
}
.element_config_item {
  width: 33.333%;
  margin-bottom: 15px;
}
