.ant-layout-header.header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  z-index: 999;

  img {
    height: 35px;
  }

  ul {
    flex: 1;
    justify-content: flex-end;
  }
}

.material-icons-outlined.ant-menu-item-icon {
  font-size: 20px;
}

.user_profile_dropdown {
  color: #fff;
  padding: 0 23px;
  margin-right: 8px;
  transition: all ease 0.2s;

  @media screen and (max-width: 900px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(20, 20, 20, 0.718);
  }

  .ant-avatar-circle {
    margin-right: 8px;
  }
}

.contained_image {
  width: 100%;
  height: 100%;
  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all ease 0.3s;

  &:hover {
    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.678);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
    }

    .anticon {
      opacity: 1;
    }
  }

  .anticon {
    opacity: 0;
    transition: all ease 0.4s;
    z-index: 5;
    font-size: 25px;
  }
}

.align_center {
  text-align: center;
  margin-bottom: 5px;
}

.inline_inputs {
  display: flex;
  justify-content: space-between;

  .half_input {
    width: calc(50% - 2.5px);

    input {
      width: 100% !important;
    }
  }
}

.ant-layout {
  position: relative;
}

.custom_loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f7f4f4;
  z-index: 9999999999999999;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  @media (max-width: 992px) {
    &:before {
      content: '';
      display: none;
    }
  }
}
