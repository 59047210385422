.preview_layout {
  display: flex;
  width: 100%;
  padding-bottom: 50px;
}

.field_preview {
  border-left: 2px solid #eeeeee;
  margin-left: 50px;
  padding: 50px;
  background: #e5e5e5;
  min-width: 500px;
  flex: 1;

  h3 {
    margin-bottom: 25px;
  }
}

.ql-editor {
  background-color: #fff !important;
}

.custom_tag {
  font-size: 15px;
  padding: 5px 12px 3px 18px;
  background-color: #eee;
  border-radius: 22px;
  display: inline-block;
  margin-bottom: 0px;
}

.form_field {
  h1 {
    font-size: 2em;
  }
}

.inline_switch {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.preview_layout {
  .ant-form.ant-form-vertical {
    width: 500px;
  }
}

.show_rich_text {
}

.hide_rich_text {
}

.input_sep {
  width: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
}

.options_filter {
  width: 100%;

  td {
    padding-bottom: 6px;

    &:first-child {
      padding: 8px 6px 6px 10px;
      padding-right: 20px;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

.percentage_table {
  td {
    padding: 4px;
  }
}

.color_field {
  .pallet_line button {
    margin: 0px;
    width: 43px;
    height: 33px;
  }
}

.nested_values {
  padding: 10px 25px;
  margin-bottom: 17px;
  background-color: #f5f5f5;

  &:empty {
    padding: 0px;
  }
}

.add_task_btn {
  width: calc(100% - 20px);
  margin-left: 16px;
  margin-top: 25px;
}
