.table_config {
  label {
    width: 180px;
  }
  .ant-input-number,
  input,
  .ant-form-item-control-input {
    width: 100%;
    max-width: 300px;
  }
  .config_group {
    margin-top: 20px;
  }
}
