.manage_page {
  .nestable-item {
    padding: 10px 15px;
    border: 1px solid rgb(225, 225, 225);
    background-color: rgba(238, 238, 238, 0.3);
  }
}
.page_list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
