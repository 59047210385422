.pallet_line {
  display: flex;
  button {
    background-color: aqua;
    width: 50px;
    height: 40px;
    margin-top: 15px;
    margin-right: 10px;
    border: 0px;
    cursor: pointer;
    transition: all ease 0.2s;
    position: relative;
    z-index: 3;
    // &:hover{
    //     transform: scale(2);
    //     z-index: 20;
    // }
  }
}

.float_sketch {
  position: absolute;
  z-index: 9;
  left: 0px;
  top: 25px;
  display: none;
}

.inline_color_edit {
  position: relative;
  &:hover {
    button {
      transform: scale(1.8);
      z-index: 20;
    }
    .float_sketch {
      display: block;
      z-index: 40;
    }
  }
}
