.knowledge_area {
  display: flex;
  justify-content: space-between;
  // padding: 20px;
  // max-width: 900px;
  min-height: 400px;
  margin-top: 0px;
}

.cards_knowledge_sidebar {
  border: 1px solid #e5e5e5;
  width: 300px;
  padding: 15px;
  background-color: #e5e5e5;
  height: calc(44vh - 74px);
  margin: 10px 0px;
  overflow-y: auto;
  height: calc(50% - 35px);
  border-radius: 10px;
}

.cards_knowledge_container {
  border: 1px solid #e5e5e5;
  background-color: #f2f2f2;
  flex: 1;
  margin-left: 25px;
  padding: 15px;
  border-radius: 10px;
}

.knowledge_box {
  user-select: none;
  padding: 16px;
  // border: 1px solid #e2e2e2;
  margin: 0 0 8px 0;
}

.cards_knowledge_box {
  display: inline-block;
  cursor: pointer;
  margin: 5px;
}

.flip-card {
  background-color: transparent;
  max-width: 350px;
  // width: 50%;
  width: 33.333%;
  width: calc(33.33% - 10px);
  // min-width: 250px;
  height: 380px;
  perspective: 1000px;
  padding: 0px;
  border-radius: 10px;
  max-width: 265px;
}

.flip-card-inner {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin: 5px;
}

.flip-card.clicked .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-middle,
.flip-card-back {
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0px;
  top: 0px;
  padding: 15px;
  overflow-y: scroll;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
}

.flip-card-back {
  // background-color: #5f007c;
  background: linear-gradient(to right, red, purple);
  background: linear-gradient(to right, #4e4e4e, black);
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    color: #ffffff;
  }
}

.is_dragging {
}
