.pdf_element {
  background-color: #fff;
  border: 1px solid #e6e5e5;
  padding: 15px;
  margin-bottom: 35px;
  position: relative;
  textarea {
    width: 100%;
    background-color: #e4e0e0;
    border: 0px;
    padding: 8px;
  }
}
.nestable-item:last-child {
  .pdf_element {
    margin-bottom: 0px;
  }
}

.pdf_page {
  padding: 25px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  .form_builder {
    border: 0px;
  }
}

.bd_set_btn {
  background-color: black;
  color: #fff;
  padding: 5px 11px 6px;
  font-size: 12px;
  border-radius: 4px;
}

.cover_bg_controls {
  background-color: #fff;
  text-align: right;
  padding-bottom: 9px;
}

.elements_control {
  background-color: #fff;
  position: absolute;
  right: 19px;
  top: -4px;
  padding: 1px 16px;
  box-shadow: 3px 1px 11px #8888889c;
  border-radius: 15px;
  z-index: 99;
  button.conditions {
    padding: 0px;
    width: 24px;
    margin-right: 15px;
  }
  button {
    padding: 0px;
    width: 24px;
    margin-left: 7px;
  }
}

.align_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.each_group_field {
  margin: 0px 5px;
}
.fake_page {
  background-color: #fff;
  padding: 25px;
  margin-top: 25px;
  min-height: 900px;
}

.page_fake_cont {
  background-color: #000;
  padding: 15px;
}
