.scrollable-steps {
  overflow-x: auto;
  overflow-y: hidden; /* Ensure no vertical overflow */
  white-space: nowrap;
  width: 100%;
  display: flex;
  height: auto; /* Set height to auto */
  padding-bottom: 20px;
  scroll-behavior: smooth;
}

.scrollable-steps .ant-steps-item {
  flex: 0 0 auto; /* This means the item can't grow, isn't allowed to shrink, and retains its auto computed size */
}

.steps-content {
  height: calc(100vh - 292px);
  overflow-y: scroll;
}
.steps-action {
  text-align: right;
}
.dummy_content {
  background-color: #e9ecf0;
  padding: 25px;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wizard_buttons {
  width: 200px;
  margin-left: 25px;
}

.score_card {
  padding: 25px 35px;
  background-color: #fff;
  width: 100%;
  max-width: 600px;

  th {
    text-align: center;
    font-size: 20px;
    padding-bottom: 15px;
  }
  td {
    padding: 5px;
  }
  .bordered_cell {
    border: 1px solid #eeeeee;
  }
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #d9d9d9;
  }
}
