.chatbox-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 5px;
    text-align: left;
  }
}

.chatbox-icon {
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.chatbox {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #f1f1f1;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.chat-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin: 5px 0;
  padding: 8px;
  border-radius: 5px;
  max-width: 70%;
  word-break: break-word;
}

.message.sent {
  background-color: #e0f7fa;
  align-self: flex-end;
}

.message.received {
  background-color: #e1bee7;
  align-self: flex-start;
}

.chat-footer {
  background-color: #f1f1f1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat-footer input {
  padding: 10px;
  margin-bottom: 5px;
}

.suggestions-container {
  display: flex;
  flex-direction: column;
}

.suggestion {
  padding: 5px;
  background-color: #f0f0f0;
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 5px;
}

.suggestion:hover {
  background-color: #e0e0e0;
}
