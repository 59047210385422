.sortable_fields {
  list-style: none;
  padding: 0px;
}
.checklist_checkbox {
  height: 16px;
  margin-right: 4px;
}
.draggable_list {
  background-color: #f3f3f3;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 15px;

  .ant-select {
    margin: 0 8px;
  }
}
.item_name {
  display: inline-block;
  width: 150px;
  font-weight: 500;
}
.remove_list_item {
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
    color: red;
  }
}

.master_rule {
  background-color: #eee;
  padding: 30px 25px;
  margin-bottom: 15px;
  max-width: 1000px;
  position: relative;
  margin-left: 50px;
  &:before {
    content: attr(data-index);
    margin-right: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 21px;
    font-weight: 600;
    left: -39px;
    top: 0px;
    background-color: #c3c2c2;
  }
}

.rule {
  background-color: #e7e7e7;
  border: 2px dashed #d9d9d9;
  padding: 11px 15px;
  max-width: 854px;
  margin-bottom: 10px;
  flex: 1;
  p {
    margin: 0px;
  }
}
.rule,
.result {
  select,
  input[type='text'],
  input[type='number'],
  input {
    padding: 4px 10px;
    height: 30px;
    margin: 0 5px;
    border: 2px solid #dfdede;
    border-radius: 2px;
    text-transform: capitalize;
  }
}
.result {
  margin-top: 20px;
}

.rules {
  display: flex;
  width: 900px;
  align-items: flex-end;
  button {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
.rule_inner_cont {
  display: flex;
  align-items: center;
  width: 100%;
}
.rules_container {
  max-width: 900px;
  flex: 1;
}
.statements {
  margin-top: 15px;
  .ant-picker-range,
  .ant-picker {
    background-color: transparent;
    border: 0px;
  }
  .ant-picker-input input {
    background-color: #fff;
  }
  .ant-select-selector {
    padding: 0px 4px;
    height: 29px;
    min-width: 65px;
  }
}
.remove_master_rule {
  position: absolute;
  right: -40px;
  top: 10px;
}
.draggable_list.group_field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.checkbox_group {
  background-color: #fff;
  display: inline-block;
  padding: 4px 6px;
  margin-right: 3px;
  border: 1px solid #d9d9d9;
}
