.task {
  background: #fff;
  border: 1px solid #1a192b;
  border-radius: 3px;
  color: #222;
  font-size: 12px;
  padding: 10px 15px;
  text-align: center;
  width: 280px;
}

.task_foot {
  display: flex;
  justify-content: space-between;

  b {
    display: block;
  }
}

.core_task_data {
  text-align: left;

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.react-flow__edge-path {
  stroke: #5b5b61 !important;
  stroke-width: 4px !important;
}

.react-flow__handle {
  background: #555 !important;
  border: 1px solid #fff !important;
  border-radius: 100% !important;
  height: 13px !important;
  width: 13px !important;
}

.react-flow__handle-top {
  top: -7px !important;
}

.react-flow__handle-bottom {
  bottom: -7px !important;
}

.task_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .anticon {
    font-size: 14px;
    opacity: 0;
  }
}
.task:hover {
  .task_heading {
    .anticon {
      opacity: 1;
    }
  }
}
