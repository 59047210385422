.edgebutton {
  padding: 0px;
  margin: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2196f3;
  color: #fff;
  cursor: pointer;
  .ant-menu-item-icon {
    font-size: 14px !important;
  }
}
.edgebutton_foreignobject,
.edgebutton_foreignobject > body {
  background-color: transparent;
}
