.contact_card {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    margin-top: 0px;
  }
  .base_form {
    @media (max-width: 767px) {
      margin-top: 50px;
      box-shadow: none;
    }
    flex: 1;
  }
  p {
    font-size: 16px;
  }
}
.contact_info {
  max-width: 450px;
  margin-right: 45px;
  @media (max-width: 767px) {
    margin-right: 0px;
  }
  flex: 1;
}
.contact_methods {
  margin-top: 45px;
  a {
    display: flex;
    color: #000;
    font-size: 18px;
    margin-bottom: 15px;
    span {
      margin-right: 10px;
      font-size: 30px;
      color: #dfa410;
    }
  }
}
