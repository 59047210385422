header {
  position: relative;
  background-image: linear-gradient(to bottom, #2d3136, #393e46 62%, #393e46, #393e46);

  @media (max-width: 992px) {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('bg.png');
      background-size: cover;
    }
  }

  .home_nav {
    background: transparent;
  }
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 100px;
  position: relative;
  z-index: 2;
  @media (max-width: 992px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  img {
    height: 400px;
    @media (max-width: 1400px) {
      height: 370px;
    }
    @media (max-width: 1200px) {
      height: 350px;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  h1 {
    font-size: 48px;
    color: #fff;
    max-width: 500px;
    @media (max-width: 1400px) {
      font-size: 43px;
    }
    @media (max-width: 992px) {
      font-size: 30px;
      text-align: center;
    }
  }
  .btn {
    margin-top: 25px;
    @media (max-width: 992px) {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: 220px;
      text-align: center;
    }
  }
}

.features {
  padding: 70px 0px 100px;
  @media (max-width: 992px) {
    padding: 70px 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 50px 0px 30px;
  }
  h2 {
    color: #002371;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 1.3;
    }
  }
}
.subheading {
  color: #606060;
  font-size: 20px;
  margin-bottom: 70px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 17px;
    margin-bottom: 40px;
  }
}

.features_box_cont {
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    display: block;
  }
}

.features_box {
  background-color: rgba(57, 62, 70, 0.08);
  width: calc(33.3333% - 20px);
  max-width: 400px;
  padding: 35px 35px 35px;
  @media (max-width: 992px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    padding: 35px 15px 15px;
    width: 100%;
    background-color: transparent;
    max-width: 500px;
  }
  img {
    display: block;
    width: 150px;
    margin: 0 auto;
  }
  h3 {
    text-align: center;
    margin-top: 25px;
    font-size: 22px;
  }
  p {
    text-align: left;
    margin-bottom: 0px;
    color: #393e46;
    font-size: 17px;
  }
}

.pricing {
  background-color: #22252a;
  padding: 70px 0px;
  @media (max-width: 768px) {
    padding: 70px 0px 30px;
  }
}

.pricing_section {
  text-align: center;
  h2 {
    color: #fff;
    font-size: 35px;
  }
  p {
    color: #f5d686;
    font-size: 17px;
    max-width: 600px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 40px;
    font-weight: 400;
  }
}

.pricing_cont {
  display: none;
  justify-content: space-between;
  // max-width: 768px;
  margin: 0 auto;
  padding: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #09090a;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 10px;
    display: block;
  }
}

.pricing_model {
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  margin: 0 8px;
  max-width: 400px;

  @media (max-width: 992px) {
    width: calc(50% - 15px);
  }
  @media (max-width: 768px) {
    &:first-child {
      margin-top: 0px;
    }
    width: 100%;
    margin: 40px auto;
  }

  h3 {
    color: #002371;
    font-size: 25px;
    margin-bottom: 3px;
  }
  b {
    color: #535353;
    font-size: 17px;
    font-weight: 500;
  }
  p {
    margin-bottom: 0px;
  }
  a {
    display: inline-block;
    background-color: #fbbe1e;
    text-decoration: none;
    color: #141414;
    border-radius: 3px;
    padding: 8px 20px;
    &:hover {
      background-color: #f3b006;
      color: #141414;
    }
  }
}

.pricing_foot {
  text-align: center;
  padding: 20px 0px;
}

.pricing_head {
  padding: 25px 20px;
  text-align: center;
}

.pricing_features {
  height: 300px;
  background-color: #eee;
  ul {
    list-style: none;
    padding: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
    li {
      position: relative;
      padding: 5px 0px 5px 30px;
      font-size: 16px;
      color: #6f6f6f;
      span {
        position: absolute;
        left: 0px;
        top: 6px;
        color: #069b3a;
      }
    }
  }
}

.highlight_features {
  a {
    color: #e6a903;
  }
  padding: 70px 0px;
  background-color: #fcfcfc;
  @media (max-width: 992px) {
    background-color: rgb(241, 240, 240);
  }
  h2 {
    color: #002371;
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.highlight {
  display: flex;
  align-items: center;
  margin-top: 150px;
  @media (max-width: 1200px) {
    margin-top: 90px;
  }
  @media (max-width: 992px) {
    display: block;
  }
  @media (max-width: 768px) {
    padding: 0px 15px;
  }
  &.reverse {
    flex-direction: row-reverse;
    div {
      padding-left: 0px;
      padding-right: 100px;
      @media (max-width: 992px) {
        padding-right: 0px;
        display: block;
      }
    }
  }
  h3 {
    font-size: 27px;
    @media (max-width: 1200px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 16px;
    color: #888888;
  }
  img {
    height: 400px;
    @media (max-width: 992px) {
      height: 300px;
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    & + div {
      padding-left: 100px;
      @media (max-width: 992px) {
        padding-left: 0px;
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }
}

.pricing_tabs {
  display: inline-block;
  background-color: #33383f;
  width: 50%;
  text-align: center;
  padding: 25px;
  color: #646464;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    background-color: #25292e;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 0px;
    color: #777777;
  }
  p {
    margin-bottom: 0px;
  }
}

#individual:checked ~ .individual_price {
  display: flex;
}
#business:checked ~ .business_price {
  display: flex;
}
#individual + label {
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
}
#business + label {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
}
.pricing_tab_input:checked + label.pricing_tabs {
  background-color: #09090a;
  color: rgb(150, 150, 150);
  h3 {
    font-size: 22px;
    margin-bottom: 0px;
    color: #fff;
  }
}
