.custom_bar_chart_cont {
  position: relative;
}

.custom_bar_chart {
  display: flex;
  position: relative;
  input {
    width: 0px !important;
    height: 0px !important;
    overflow: hidden !important;
    position: absolute;
  }
  label {
    flex: 1;
    margin-left: -1px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.score_count {
  text-align: center;
  font-size: 16px;
  margin-top: 9px;
  font-weight: 500;
}
