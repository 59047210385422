.static_admin_setting {
  margin: 10px;
  padding: 35px 40px;
  background-color: #fff;
  min-height: calc(100vh - 108px);
  h1 {
    font-size: 25px;
  }
  .ql-container.ql-snow,
  .ql-editor.ql-blank {
    min-height: 600px;
    background-color: rgb(248, 248, 248);
  }
}

.contact_details_setting {
  padding: 0px;
  margin-top: 45px;
  box-shadow: none;
  max-width: 100%;
  align-items: center;
  width: 100%;
  button {
    width: 150px;
    padding: 6px 10px;
  }
}
