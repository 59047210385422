.super_container {
  width: 100%;
  overflow-x: auto;
}

.chart-container {
  width: 100%; // Container takes full width of its parent
  // overflow-x: auto;   // Enable horizontal scrolling
  overflow-y: hidden;
}

.chart {
  width: 100%; // Chart width is dynamic but contained within .chart-container
  height: 250px; // Fixed height for all charts
  margin-bottom: 20px;
}

.lefe_and_expenses_chart {
  width: 100%; // Chart width is dynamic but contained within .chart-container
  height: 125px; // Fixed height for all charts
  margin-bottom: 20px;
}

.investment_chart_container {
  display: flex;
  flex: 1;
}
.stratergy_sidebar {
  margin-left: 15px;
  border: 1px solid #eee;
  background-color: #bdbdbd;
  width: 250px;
}
