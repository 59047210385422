.pdf_page_view_modal {
  &,
  .ant-modal-content {
    height: 100vh !important;
    width: 100vw !important;
    margin: 0;
    top: 0;
    max-width: 100vw !important;
    background-color: #000;
  }

  .ant-modal-header {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 9999;
  }

  .ant-modal-body {
    height: calc(100vh - 51px);
    padding-top: 80px;
    padding-bottom: 83px;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    background-color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 9999;
  }

  .ant-modal-close {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 99999;
  }
}

.each_pdf_page {
  background-color: #fff;
  box-shadow:
    rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  padding: 25px 35px;
  margin: 20px 0px;
  min-height: 1400px;
  overflow: auto;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
