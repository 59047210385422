.portfolio {
  width: 100%;
  border-spacing: 0;
  // border: 1px solid black;

  td:first-child {
    width: 40px;
  }

  th {
    border: 1px solid #e7e7e7;
    &:first-child {
      border-right: 0px;
      border-bottom: 0px;
    }
    &:nth-child(2) {
      border-left: 0px;
      border-bottom: 0px;
    }
  }
  input {
    border: 0px;
    background-color: transparent;
  }
}
